<template>
  <div>
    <div
      :style="
        'background-image: url(' +
        require('@/assets/images/spripruef/background.jpg') +
        ');'
      "
      class="rounded-t bg-cover bg-center h-48"
    ></div>
    <div class="bg-blue-700 text-gray-200 p-4 py-6">
      <div class="uppercase text-xs font-bold">Willkommen bei</div>
      <h1 class="text-xl">
        SpriPrüf - Prüforganisation für stationären Brandschutz
      </h1>
    </div>
    <div class="p-4">
      <p class="my-6 text-xl text-center">
        Prüfsachverständiger Herr Dipl.-Ing. (FH) Markus Hösl
      </p>

      <div>
        <div class="flex items-stretch my-8">
          <div class="w-1/6 flex-none flex justify-center">
            <img
              src="@/assets/images/spripruef/safety-helmet.png"
              class="h-14 mx-auto"
              alt=""
            />
          </div>
          <div class="justify-center flex-1">
            Prüfsachverständiger für die Prüfung sicherheitstechnischer Anlagen
            und Einrichtungen, Fachrichtung Feuerlöschanlagen
          </div>
        </div>
        <div class="flex items-stretch justify-center my-8">
          <div class="justify-center w-1/6 flex-none">
            <img
              src="@/assets/images/spripruef/law.png"
              class="h-14 mx-auto"
              alt=""
            />
          </div>
          <div class="justify-center flex-1">
            Zulassung gemäß §§22, 23 Satz 1 Nr. 4 der Verordnung über die
            Prüfingenieure, Prüfämter und Prüfsachverständigen im Bauwesen
            (PrüfVBau)
          </div>
        </div>
        <div class="flex items-stretch justify-center my-8">
          <div class="justify-center w-1/6 flex-none">
            <img
              src="@/assets/images/spripruef/team.png"
              class="h-14 mx-auto"
              alt=""
            />
          </div>
          <div class="justify-center flex-1">
            <div class="flex justify-middle items-stretch text-left">
              Listung bei der Bayrischen Ingenieurekammer Bau, deutschlandweite
              Zulassung
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-3/4 mx-auto">
        <h2 class="text-lg my-4 text-center">Kontakt</h2>
        <div class="grid md:grid-cols-2 my-2">
          <div class="leading-6.75">
            SpriPrüf<br />
            Erhard-Stangl-Ring 14<br />
            84435 Lengdorf
          </div>
          <div>
            <table>
              <tr>
                <td class="px-2 pl-0">Telefon:</td>
                <td class="px-2">+49 8083 908598</td>
              </tr>
              <tr>
                <td class="px-2 pl-0">Telefax:</td>
                <td class="px-2">+49 8083 908597</td>
              </tr>
              <tr>
                <td class="px-2 pl-0">E-Mail:</td>
                <td class="px-2">
                  <a href="mailto:info@spripruef.de">info@spripruef.de</a>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spripruef",
  components: {},
};
</script>
